import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"

import NewsTile from "../components/news/newsTile.js"
import SEO from "../components/SEO"
import BlogCard from "../components/news/BlogCard"
import { PageTitle, SubTitle } from "../components/SiteComponents"
import {
  addDateToSortByField,
  sortPostByDate,
  monthArray,
} from "../helperFunctions"

const News = ({ data }) => {
  const newsPostsArray = data.newsPosts.edges
  // const date = newsPosts.publishedAt
  //   ? newsPosts.publishedAt
  //   : newsPosts._createdAt

  // Sort array by new date (only if it is not null)

  // const sortedPostsByDate =

  // Create a deep copy of the array without references using JSON methods
  // https://www.freecodecamp.org/news/how-to-clone-an-array-in-javascript-1d3183468f6a/
  const newArray = JSON.parse(JSON.stringify(newsPostsArray))

  // Add a new field to post object to sort by
  const arrayWithNewDateField = addDateToSortByField(newArray)
  // Sort by the new date field
  const sortedPosts = sortPostByDate(arrayWithNewDateField)

  return (
    <Layout>
      <div className="flex flex-col bg-gray-300 pb-8 flex-grow">
        <SEO title="News" />
        {/* News Tile Container */}
        <PageTitle className="mb-5 text-nofnecGreen mt-32 md:mt-20">
          News
        </PageTitle>
        {sortedPosts.length > 0 ? (
          <div className="grid mx-10 md:mx-20 gap-y-8 justify-center">
            {sortedPosts.map((post, index) => {
              const mainImage = post.mainImage
                ? post.mainImage.asset.fluid
                : null
              {
                /* trim summary to fit */
              }
              const summary = post.summary ? post.summary.substr(0, 100) : ""
              return (
                <BlogCard
                  title={post.title}
                  text={summary}
                  fluid={mainImage}
                  handle={post.slug.current}
                  blogIndex={index}
                  date={post.dateString}
                />
              )
            })}
          </div>
        ) : (
          <SubTitle>No posts yet. Check again soon!</SubTitle>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query NewsQuery {
    newsPosts: allSanityPost(
      sort: { fields: [publishedAt, _createdAt], order: [DESC, DESC] }
    ) {
      edges {
        node {
          ...BlogCard
        }
      }
    }
  }
`

export default News
